import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Toggle, ToggleLink, Link, CouponIcon, LabeledCheckbox, Theme, InfoIcon, Dropzone, Typography, SwitchStyled, ToggleSwitchStyled, } from '@commonsku/styles';

import RichTextEditor from '../helpers/RichTextEditor';

import { createMetadata, updateMetadata, deleteMetadata } from '../../actions';
import { createDiscount, updateDiscount, finalizeUpdateDiscount, deleteDiscount } from '../../actions/discount';
import { enableInventory, loadInventory } from '../../actions/inventory';
import { updateShop, finalizeUpdateShop, createShippingRange, updateShippingRange, deleteShippingRange } from '../../actions/shop';
import { createLoadOrder, updateOrder } from '../../actions/order';
import { createContactListPopup, createAddressListPopup, createMetadataListPopup } from '../../actions/popup';
import { createUpdateAddress } from '../../actions/address';
import { createUploadFile } from '../../actions/file';

import ReactTooltip from "react-tooltip";

import {
  getAddressDropdown,
  getContactDropdown,
  getEventTypeOptions,
  getJobTemplateOptions,
  getStatusOptions,
  getTags,
  getUserOptions,
  getAddressOptions,
  getContactOptions,
  getCurrencyOptions,
  getChatBotOptions
} from '../../selectors/dropdowns';

import { getAddress, getCompanyData, getContact, getInventoryByShopId, getOrderByNumber } from '../../selectors';

import LabelledInput from '../LabelledInput';
import LabelledCheckbox from '../LabelledCheckbox';
import LabelledSelect from '../LabelledSelect';
import LabelledRadio from '../LabelledRadio';
import LabelledAddress from '../LabelledAddress';
import LabelledControl from '../LabelledControl';
import AutosuggestTagsInput from '../AutosuggestTagsInput';
import TagsInput from 'react-tagsinput';
import { InventoryConfigureLoader as InventoryConfigure } from './InventoryConfigure';
import AvalaraCategorySelect from '../avalara/AvalaraCategorySelect';

import { toTimestamp, getIdentityUtils } from '../../utils';

import { useAvalara } from '../avalara/hooks';
import { find, get, isEmpty, map } from 'lodash';
import AddressErrorPopup from '../avalara/AddressErrorPopup';
import Tax from '../../models/Tax';
import { isAvalaraShop } from '../../helpers/order';
import MetaData from './MetaData/Metadata';
import ShopCheckoutConfig from './ShopCheckoutConfig';

const checkout_recipients = [
  {key: 'client_rep', value: 'Client Rep'},
  {key: 'order_rep', value: 'Order Rep'},
  {key: 'shop_contact', value: 'Shop Contact'},
];

const AvalaraLabelledAddress = ({ tax_id, address, onResolve, ignoreError, ...props }) => {
  const avalara = useAvalara();
  const [resolution, setResolution] = useState({});
  useEffect(() => {
    if (address) {
      avalara.resolveAddress({ address_id: address.address_id })
      .then(({ json }) => {
        if (onResolve) {
          onResolve(json);
        }
        setResolution(json);
      }).catch(err => {
        console.log(err);
      });
    }
  }, [address]);

  return [
    <LabelledAddress key="avalara-labelled-address" address={address} {...props}/>,
    tax_id === Tax.avalara_tax_id &&
    (!isEmpty(resolution.error) || !isEmpty(get(resolution, 'address_resolution.messages'))) &&
    <AddressErrorPopup
      key="avalara-address-error-popup"
      error={resolution.error}
      resolution={resolution.address_resolution}
      onClose={() => {
        setResolution({});
      }}
      ignoreError={() => {
        setResolution({});
      }}
    />
  ];
};

class Configuration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shipping_ranges: props.shop.shipping_ranges,
      add_label: '',
      inventoryHeader: null,
      toggleButtonState : props.shop.is_shop_free,
    };

    this.handleShopImageUpload = this.handleShopImageUpload.bind(this);
    this.handleSelectType = this.handleSelectType.bind(this);
    this.handleAllowCreditCardCheckout = this.handleAllowCreditCardCheckout.bind(this);
    this.handleChangeRangeInput = this.handleChangeRangeInput.bind(this);
    this.toggleButtonClass = this.toggleButtonClass.bind(this);
    this.handleAllowFreeProduct = this.handleAllowFreeProduct.bind(this);
    this.handleFreeShopSingleUse = this.handleFreeShopSingleUse.bind(this);
    this.handleFreeShopDomains = this.handleFreeShopDomains.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ shipping_ranges: nextProps.shop.shipping_ranges });
  }

  componentDidMount() {
    const { order: { loaded, order_id }, loadOrder, shop, inventory, loadInventory } = this.props;
    if (!loaded) {
      loadOrder(order_id);
    }
    if (!!shop.inventory_id && !inventory) {
      loadInventory();
    }
  }

  toggleButtonClass(buttonId){
    const btnActiveState = buttonId == 1 ? true : false;
    this.setState({toggleButtonState:btnActiveState});
  }
  handleFreeShopDomains(valid_tags){
    const { shop, onUpdateShop } = this.props;
    onUpdateShop('free_shop_data', shop.free_shop_data)(valid_tags);
  }
  handleAllowFreeProduct(buttonId){
    const newValue = buttonId == 1 ? 1 : 0;
    const { shop, onUpdateShop, onUpdateShippingRange} = this.props;
    onUpdateShop('is_shop_free', shop.is_shop_free)(newValue);
    if (newValue == 1) {
     if(shop.default_tax_id ) {
      onUpdateShop('default_tax_id',shop.default_tax_id)('');
     }
     if(shop.allow_credit_card_checkout == 1){
       onUpdateShop('allow_credit_card_checkout',shop.allow_credit_card_checkout)('');
     }
     if(shop.per_checkout_limit){
       onUpdateShop('per_checkout_limit',shop.per_checkout_limit)('');
     }
    if(shop.force_minimum_qty == 1) {
      onUpdateShop('force_minimum_qty',shop.force_minimum_qty)(0);
    }
     Object.values(this.state.shipping_ranges).map((r, i) =>{
      if(r.shipping_range_id){
        onUpdateShippingRange(r.shipping_range_id, 'value', r.value)('');
      }
     });
    }
    this.setState({toggleButtonState:newValue});
  }
  handleFreeShopSingleUse(newValue){
    const {onUpdateShop,shop}=this.props;
    onUpdateShop('single_use_for_free_shop', shop.single_use_for_free_shop)(newValue);
  }
  handleShopImageUpload(files) {
    const { shop, onUploadFile, onUpdateShop } = this.props;

    onUploadFile(files[0]).then(
        action => {
          onUpdateShop('portal_image', shop.portal_image)(action.payload.data.file_name_original);
        }
    );
  }

  handleChangeRangeInput(field, value, shipping_range_id) {
    this.setState({
      shipping_ranges: Object.assign({}, this.state.shipping_ranges, {
        [shipping_range_id]: Object.assign({}, this.state.shipping_ranges[shipping_range_id], {
          [field]: value
        })
      })
    });
  }

  handleChangeInput(field, value) {
    this.setState({ [field]: value });
  }

  handleSelectType(field, previous_value, value) {
    const { shop, shop_billing_contacts, shop_shipping_contacts, shop_addresses, onUpdateShop, onAddContact, onAddBillingAddress, onAddShippingAddress } = this.props;

    let allowUpdate = false;
    if(value === 'LIST') {
      switch(field) {
        case 'billing_contact_type':
          if(!shop_billing_contacts.length) {
            onAddContact('BILLING');
          }else{
            allowUpdate = true;
          }
          break;
        case 'shipping_contact_type':
          if(!shop_shipping_contacts.length) {
            onAddContact('SHIPPING');
          }else{
            allowUpdate = true;
          }
          break;
        case 'billing_address_type':
          if(!shop_addresses.filter(a => 'BILLING' === a.address_type).length) {
            onAddBillingAddress();
          }else{
            allowUpdate = true;
          }
          break;
        case 'shipping_address_type':
          if(!shop_addresses.filter(a => 'SHIPPING' === a.address_type).length) {
            onAddShippingAddress(shop.allow_credit_card_checkout);
          }else{
            allowUpdate = true;
          }
          break;
      }
    }else{
      allowUpdate = true;
    }

    if(allowUpdate) {
      onUpdateShop(field, previous_value)(value);
    }
  }

  handleAllowCreditCardCheckout(e) {
    const { shop, onUpdateShop, onAddShippingAddress } = this.props;

    onUpdateShop('allow_credit_card_checkout', shop.allow_credit_card_checkout)(e);

    if (e == 1 && shop.aggregate == 0) {
      onUpdateShop('shipping_address_type', shop.shipping_address_type)('LIST');
      onAddShippingAddress(e);
    }
  }

  renderCreditCardSection() {
    const { shop } = this.props;

    if(shop.aggregate == 1) {
      return (
        <div className="row">
          <div className="small-12 columns">
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
            <Typography.Text fontSize={'20px'} bold style={{ paddingRight: 24 }}>
              Credit Card Checkout
            </Typography.Text>
            <SwitchStyled
              size="medium"
              selected={+shop.allow_credit_card_checkout === 1}
              onClick={() => {
                this.handleAllowCreditCardCheckout(+shop.allow_credit_card_checkout === 1 ? 0 : 1);
              }}
              data-testid="allow_credit_card_checkout_toggle"
            />
            </div>
          </div>
          <div className="small-12 columns">
            <Typography.Text className="explanation">
              Allows customer to checkout with credit card&nbsp;&nbsp;
            </Typography.Text>
          </div>
          <div className="small-12 medium-12 columns" style={{width: '100%', marginLeft: 0, marginRight: 0, padding: 0}}><hr/></div>
        </div>
      );
    }else{
      return (
        <LabelledCheckbox
          className= "small-12 medium-6 large-3 columns"
          style={ { marginTop: '2rem' } }
          label="Credit card checkout"
          value={ shop.allow_credit_card_checkout }
          onChange={ value => this.handleAllowCreditCardCheckout(value) }
        />
      );
    }
  }

  renderGeneralSection() {
    const {
      shop,
      event_types,
      project_templates,
      statuses, tags,
      users, onChangeShop,
      onUpdateShop,
      currencies,
      chat_bots,
			shop_addresses,
			order,
			onUpdateOrder,
    } = this.props;

    const bots = [{key: '', value: '--- Disable Chat ---'}, ...chat_bots];

    const shop_shipping_addresses = shop_addresses.filter(a => 'SHIPPING' === a.address_type);
    const tagOptions = map(tags, (tag) => {
      return {...tag, key: tag.tag_id};
    });

    return (
      <div className="row" style={{ padding: '15px' }}>
        <div className="box">
          <h4>General</h4>
          <div className="row">
            <div className="row">
              <LabelledInput
                className="small-12 medium-6 large-3 columns"
                label="Shop Name"
                value={shop.shop_name}
                onChange={onChangeShop('shop_name', shop.shop_name)}
                onBlur={onUpdateShop('shop_name', shop.shop_name)}
              />
              <LabelledSelect
                className="small-12 medium-6 large-3 columns"
                label="Status"
                value={shop.status_id}
                options={statuses}
                onChange={onUpdateShop('status_id', shop.status_id)}
              />
              <LabelledSelect
                className="small-12 medium-6 large-3 columns"
                label={!shop.client_id ? 'Default Client Rep' : 'Client Rep'}
                value={shop.client_rep_id}
                options={users}
                onChange={onUpdateShop('client_rep_id', shop.client_rep_id)}
              />
              <LabelledSelect
                className="small-12 medium-6 large-3 columns"
                label="Order Rep"
                value={shop.sales_rep_id}
                options={users}
                onChange={onUpdateShop('sales_rep_id', shop.sales_rep_id)}
              />
            </div>
            <div className="row">
              <LabelledInput
                className="small-12 medium-6 large-3 columns"
                type="date"
                label="Expiry Date"
                value={shop.date_expiry}
                onChange={onChangeShop('date_expiry', shop.date_expiry)}
                onBlur={onUpdateShop('date_expiry', shop.date_expiry)}
              />
              <LabelledSelect
                className="small-12 medium-6 large-3 columns"
                label="Event Type"
                value={shop.default_event_type_id}
                options={event_types}
                onChange={onUpdateShop('default_event_type_id', shop.default_event_type_id)}
              />
              <LabelledInput
                className="small-12 medium-6 large-3 columns"
                label="Shop Contact Name"
                value={shop.contact_name}
                onChange={onChangeShop('contact_name', shop.contact_name)}
                onBlur={onUpdateShop('contact_name', shop.contact_name)}
              />
              <LabelledInput
                className="small-12 medium-6 large-3 columns"
                label="Shop Contact Email"
                value={shop.contact_email}
                onChange={onChangeShop('contact_email', shop.contact_email)}
                onBlur={value => value.trim().match(/^\S+@\S+$/) ? onUpdateShop('contact_email', shop.contact_email)(value) : onUpdateShop('contact_email', shop.contact_email)(shop.contact_email)}
              />
            </div>
            <div className="row">
              {shop.aggregate == 1 ?
                  [this.renderAggregateBillingSection(),
                  this.renderAggregateShippingSection()]
              : null}
              <LabelledSelect
                className="small-12 medium-6 large-3 columns"
                label="Project Template"
                value={shop.default_project_template_id}
                options={project_templates}
                onChange={onUpdateShop('default_project_template_id', shop.default_project_template_id)}
              />
              <div className="small-12 medium-6 large-3 columns">
                <label htmlFor="___APPLY_TAGS___">Apply tags to orders</label>
                <AutosuggestTagsInput
                  id="___APPLY_TAGS___"
                  value={map(shop.shop_tags, (tag) => {
                    return find(tagOptions, { label: tag.label }) || { label: tag.label };
                  })}
                  tags={tagOptions}
                  onChange={(value)=>{
                    onUpdateShop('shop_tags', shop.shop_tags)(map(value, 'label'));
                  }}
                />
              </div>
              {/* <LabelledInput
                className="small-12 medium-6 large-3 columns"
                label="Drift Id"
                value={shop.drift_id}
                placeholder="Leave blank if not required"
                onChange={onChangeShop('drift_id', shop.drift_id)}
                onBlur={onUpdateShop('drift_id', shop.drift_id)}
              /> */}
              <LabelledSelect
                className="small-12 medium-6 large-3 columns"
                label="Currency"
                value={shop.currency_id}
                options={currencies}
                onChange={onUpdateShop('currency_id', shop.currency_id)}
              />
              <LabelledInput
                className="small-12 medium-6 large-3 columns"
                label="Shop Password"
                value={shop.shop_password}
                placeholder="Leave blank if not required"
                onChange={onChangeShop('shop_password', shop.shop_password)}
                onBlur={onUpdateShop('shop_password', shop.shop_password)}
              />
            </div>
            <div className="row">
              <LabelledSelect
                className="small-12 medium-6 large-3 columns"
                label="Select A Chat Bot To Enable Chat"
                value={shop.chat_bot_id}
                options={bots}
                onChange={onUpdateShop('chat_bot_id', shop.chat_bot_id)}
                parentStyle={{ marginBottom: 9 }}
              />
              {shop.client_id && shop.aggregate == 0 ? this.renderCreditCardSection() : null}
              <LabelledCheckbox
                className={"small-12 medium-6 large-3 columns end"}
                style={{ marginTop: '2rem' }}
                label="Show Warnings for Prop65"
                value={shop.show_product_warnings}
                onChange={onUpdateShop('show_product_warnings', shop.show_product_warnings)}
              />
              {shop.client_id && shop.aggregate == 0 && isAvalaraShop(shop, shop_shipping_addresses) ?
              <LabelledControl
                className={"small-12 medium-6 large-3 columns end"}
                label="Default Avalara Category"
              >
                <AvalaraCategorySelect
                  value={order.default_avalara_category_id}
                  onChange={onUpdateOrder(order.order_id, 'default_avalara_category_id', order.default_avalara_category_id)}
                />
              </LabelledControl> : null}
              {shop.aggregate == 1 ? <>
                <LabelledSelect
                  className="small-12 medium-6 large-3 columns"
                  label="Email on checkout"
                  value={shop.notify_checkout_recipients.split(',')}
                  options={checkout_recipients}
                  onChange={(v) => {
                    onUpdateShop('notify_checkout_recipients', shop.notify_checkout_recipients)(v.join(","));
                  }}
                  multi
                />
                <LabelledCheckbox
                  className="small-12 medium-6 large-6 columns"
                  style={{ marginTop: '2rem', WebkitTextFillColor: shop.is_shop_free == 1 ? '#ccc' : ''}}
                  label="Enforce product minimum quantity"
                  value={shop.force_minimum_qty}
                  onChange={onUpdateShop('force_minimum_qty', shop.force_minimum_qty)}
                  disabled={shop.is_shop_free == 1 ? true : false}
                />

              </> : null}
            </div>
            <div className="row">
              <div className="small-12 columns rich-text-editor">
                <label>Splash screen / Help</label>
                <RichTextEditor
                  value={shop.introduction ? shop.introduction : ''}
                  onChange={onChangeShop('introduction')}
                  onBlur={onUpdateShop('introduction')}
                />
              </div>
            </div>
            <div>
            <div className="row">
              <div className="small-12 columns" style={{ marginTop: '1rem' }}>
                <label>Shop Image For Client Portal</label>
                <div className="shop-upload-image">
                    {shop.portal_image && <img src={shop.portal_image} style={{height: '100px'}} alt="Shop Icon" />}
                    {!shop.portal_image && <span>No image is set. Will use the first product image.</span>}
                    <Dropzone onDrop={this.handleShopImageUpload} style={{ border: 'none', display: 'inline-block', marginLeft: '0.5em' }} multiple={false} accept="image/*">
                      <button className="button" style={{ marginRight: ' 1rem' }} onClick={() => { }}>Change</button>
                    </Dropzone>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFreeProductOption() {
    const {shop,onUpdateShop,valid_tags} = this.props;
    return(
      <div className="row" style={{padding:'15px'}}>
        <div className="box">
          <h4>Free Product</h4>
          <div className="row">
          <span style={{padding:'15px'}}>Allows you to limit customers to one free product per checkout (pricing removed).</span>
          <div  style={{marginTop:'-1rem'}}className="button-group compact-detailed-switch ">
            <Col style={{paddingTop:'10px'}}>
            <Toggle>
          <ToggleLink id="1" selected={this.state.toggleButtonState == 1} style={ {fontSize: '17px', lineHeight: '28px'}} onClick={e=>{e.preventDefault();this.handleAllowFreeProduct(1);}}>On</ToggleLink>
       <ToggleLink id ="0"selected={this.state.toggleButtonState == 0 } style={{fontSize:'17px', lineHeight:'28px'}} onClick={e=>{e.preventDefault();this.handleAllowFreeProduct(0);}}>Off</ToggleLink>
       </Toggle>
       </Col>

          </div>

          </div>
          {shop.is_shop_free == 1 &&
          <div className="row" style={{padding:'15px'}}>
          <Col>
                  <LabeledCheckbox label={<div data-tip data-for="single_use_for_free_shop">Single use (per email) <InfoIcon ml={4} style={{verticalAlign: 'middle'}} />
                      <ReactTooltip id="single_use_for_free_shop">
                        <p style={{ padding: '5px 10px 5px 10px' }}>If checked, each customer can checkout only once per unique email address.</p>
                      </ReactTooltip>
                    </div>}
                    checked={shop.single_use_for_free_shop == 1}
                    onChange={e=>this.handleFreeShopSingleUse(shop.single_use_for_free_shop ==1 ?0:1)}
                    />

                </Col>
                </div>}
               {shop.is_shop_free == 1 && <div className="row" style={{padding:'15px'}}>
                <Col>
                  <label htmlFor="domains_allowed_free_shop" style={{marginBottom: "4px"}}>Valid email domains
                    <div style={{ display: 'inline-block' }} data-tip data-for="domains_allowed_free_shop">
                      <InfoIcon ml={4} style={{verticalAlign: 'middle'}}/>
                      <ReactTooltip id="domains_allowed_free_shop">
                        <p style={{ padding: '5px 10px 5px 10px' }}>
                         Checkout can be restricted to specific email domains when added to the list below.
                          <br />
                          If it is empty, customer can checkout with any email address.
                          <br />
                          The format of the domain should be a naked domain eg. commonsku.com
                        </p>
                      </ReactTooltip>
                    </div>
                  </label>

                    <TagsInput
                  value={valid_tags}
                inputProps={{ placeholder: 'Add a domain' }}

                onChange={this.handleFreeShopDomains}
              />

                </Col>
                </div>
  }
        </div>
      </div>
    );
  }
  renderContactSection() {
    const { shop, shop_billing_contacts, shop_shipping_contacts, clientContactOptions, onUpdateShop, onAddContact } = this.props;

    const contactOptions = shop.client_id ? [
      { key: 'LIST', value: 'Selecting from list' },
      { key: 'TYPED', value: 'Selecting or typing contacts' }
    ] : [
      { key: 'TYPED', value: 'Selecting or typing contacts' }
    ];

    return (
      <div className="row">
        <div className="small-12 medium-6 columns">
          <h5>Billing Name</h5>
          <LabelledRadio options={contactOptions} blockStyle="block" value={shop.billing_contact_type} onChange={value => this.handleSelectType('billing_contact_type', shop.billing_contact_type, value)} disabled={!shop.client_id} />
          {'TYPED' === shop.billing_contact_type && !!shop.client_id &&
            <label style={{ marginLeft: '2rem' }} onClick={e => onUpdateShop('add_billing_contact_to_client', shop.add_billing_contact_to_client)(shop.add_billing_contact_to_client == 1 ? 0 : 1)}>
              <input type="checkbox" readOnly={true} checked={1 == shop.add_billing_contact_to_client} />
              Add these new contacts to client's contact list upon checkout
            </label>
          }
          <a className="button" onClick={e => {e.preventDefault(); onAddContact('BILLING');}}>Edit Billing Contact List ({shop_billing_contacts.length})</a>
        </div>
        <div className="small-12 medium-6 columns">
          <h5>Shipping Name</h5>
          <LabelledRadio options={contactOptions} blockStyle="block" value={shop.shipping_contact_type} onChange={value => this.handleSelectType('shipping_contact_type', shop.shipping_contact_type, value)} disabled={!shop.client_id} />
          {'TYPED' === shop.shipping_contact_type && !!shop.client_id &&
            <label style={{ marginLeft: '2rem' }} onClick={e => onUpdateShop('add_shipping_contact_to_client', shop.add_shipping_contact_to_client)(shop.add_shipping_contact_to_client == 1 ? 0 : 1)}>
              <input type="checkbox" readOnly={true} checked={1 == shop.add_shipping_contact_to_client} />
              Add these new contacts to client's contact list upon checkout
            </label>
          }
          <a className="button" onClick={e => {e.preventDefault(); onAddContact('SHIPPING');}}>Edit Shipping Contact List ({shop_shipping_contacts.length})</a>
        </div>
      </div>
    );
  }

  renderAddressSection() {
    const { shop, shop_addresses, clientAddressOptions, onUpdateShop, onAddShippingAddress, onAddBillingAddress } = this.props;

    const shop_billing_addresses = shop_addresses.filter(a => 'BILLING' === a.address_type);
    const shop_shipping_addresses = shop_addresses.filter(a => 'SHIPPING' === a.address_type);

    const addressOptions = shop.client_id ? [
      { key: 'LIST', value: 'Selecting from list' },
      { key: 'TYPED', value: 'Selecting or typing addresses' },
    ] : [
      { key: 'TYPED', value: 'Selecting or typing addresses' }
    ];

    return (
      <div className="row" style={{ marginTop: '2rem' }}>
        <div className="small-12 medium-6 columns">
          <h5>Billing Address</h5>
          <LabelledRadio options={addressOptions} blockStyle="block" value={shop.billing_address_type} onChange={value => this.handleSelectType('billing_address_type', shop.billing_address_type, value)} disabled={!shop.client_id} />
          {'TYPED' === shop.billing_address_type && !!shop.client_id &&
            <label style={{ marginLeft: '1rem' }} onClick={e => onUpdateShop('add_billing_address_to_client', shop.add_billing_address_to_client)(shop.add_billing_address_to_client == 1 ? 0 : 1)}>
              <input type="checkbox" readOnly={true} checked={1 == shop.add_billing_address_to_client} />
              Add these new addresses to client's address list upon checkout
            </label>
          }
          <a className="button" onClick={onAddBillingAddress}>Edit Billing Address List ({shop_billing_addresses.length})</a>
        </div>
        <div className="small-12 medium-6 columns">
          <h5>Shipping Address</h5>
          <LabelledRadio options={addressOptions} blockStyle="block" value={shop.shipping_address_type} onChange={value => this.handleSelectType('shipping_address_type', shop.shipping_address_type, value)} disabled={!shop.client_id || shop.allow_credit_card_checkout == 1} />
          {'TYPED' === shop.shipping_address_type && !!shop.client_id &&
            <label style={{ marginLeft: '1rem' }} onClick={e => onUpdateShop('add_shipping_address_to_client', shop.add_shipping_address_to_client)(shop.add_shipping_address_to_client == 1 ? 0 : 1)}>
              <input type="checkbox" readOnly={true} checked={1 == shop.add_shipping_address_to_client} />
              Add these new addresses to client's address list upon checkout
            </label>
          }
          <a className="button" onClick={e => {
            e.preventDefault();
            onAddShippingAddress(shop.allow_credit_card_checkout);
          }}>Edit Shipping Address List ({shop_shipping_addresses.length})</a>
        </div>
      </div>
    );
  }

  renderInventorySection() {
    const { shop, enableInventory, hasCapabilities } = this.props;
    if (!hasCapabilities('MANAGE-INVENTORY')) {
      return null;
    }
    return (
      <div className="row" style={{ padding: '15px' }}>
        <div className="box">
        <h4>Inventory{!!shop.inventory_id && this.state.inventoryHeader}</h4>
	  {!!shop.inventory_id ?
            <InventoryConfigure shop={shop} setHeader={inventoryHeader => this.setState({ inventoryHeader })} /> :
            <div className="row" style={{ marginTop: '2rem' }}>
              <div className="small-12 columns">
                <p>Inventory and replenishment management</p>
                <button className="button" disabled={!hasCapabilities('MANAGE-INVENTORY')} onClick={enableInventory}>Enable Inventory</button>
              </div>
            </div>}
	</div>
      </div>
    );
  }

  renderAggregateBillingSection() {
    const { shop, billing_address, billing_contact, clientAddressOptions, onUpdateShop, clientContactOptions } = this.props;

    return (
      <LabelledAddress
        className="small-12 medium-6 large-3 columns"
        label="Billing Address"
        address={billing_address}
        contact={billing_contact}
        onChangeContact={onUpdateShop('billing_contact_id', shop.billing_address_id)}
        onChangeAddress={onUpdateShop('billing_address_id', shop.billing_address_id)}
        addresses={clientAddressOptions}
        contacts={clientContactOptions}
        disabled={false}
        canCreate={false}
      />
    );
  }

  renderAggregateShippingSection() {
    const { shop, shipping_address, shipping_contact, clientAddressOptions, onUpdateShop, clientContactOptions, onCreateUpdateAddress } = this.props;

    return (
      <div className="small-12 medium-6 large-3 columns">
        <label>Shipping Address</label>
        <AvalaraLabelledAddress
          address={shipping_address}
          contact={shipping_contact}
          onChangeContact={onUpdateShop('shipping_contact_id', shop.shipping_address_id)}
          onChangeAddress={onUpdateShop('shipping_address_id', shop.shipping_address_id)}
          addresses={clientAddressOptions}
          contacts={clientContactOptions}
          disabled={shop.aggregate == 1 && Object.values(shop.partials).length > 0 ? true : false}
          canCreate={false}
          showTax={false}
          zip2tax={shop.zip2tax}
          onChangeTax={onCreateUpdateAddress(shop.shipping_address_id, 'tax_id', shipping_address ? shipping_address.tax_id : '')}
          tax_id={shop.default_tax_id}
          ignoreError={(shipping_address_resolution) => {
            if (
              !isEmpty(get(shipping_address_resolution, 'error')) ||
              !isEmpty(get(shipping_address_resolution, 'address_resolution.messages'))
            ) {
              onUpdateShop('default_tax_id', shop.default_tax_id)("");
            }
          }}
        />
      </div>
    );
  }

  renderShippingChargeSection() {
    const {
      shop,
      order,
      shop_addresses,
      onAddShippingRange,
      onUpdateShippingRange,
      onDeleteShippingRange,
      onUpdateOrder
    } = this.props;

    const shop_shipping_addresses = shop_addresses.filter(a => 'SHIPPING' === a.address_type);

    return (
      <div className="row" style={{ padding: '15px' }}>
        <div className="box">
          <h4>Shipping Charge
            <span className="explanation">A shipping charge will be applied to the checkout total if the total is within the ranges below&nbsp;&nbsp;</span>
          </h4>
          {isAvalaraShop(shop, shop_shipping_addresses) && <div className="row" style={{ marginTop: '2rem' }}>
            <div className="small-12 columns section-checkout">
              <h5>Avalara Shipping Category
                <span className="explanation"> Set the Avalara tax category for shipping&nbsp;&nbsp;</span>
              </h5>
              <div className="row">
                  <div className="medium-3 columns end">
                    <AvalaraCategorySelect
                      value={order.avalara_shipping_category_id}
                      onChange={onUpdateOrder(order.order_id, 'avalara_shipping_category_id', order.avalara_shipping_category_id)}
                    />
                  </div>
              </div>
            </div>
          </div>}
          <div className="row" style={{ padding: '15px' }}>
            <table>
              <thead style={{ fontSize: '0.875rem' }}>
                <tr>
                  <td></td>
                  <td>Range
                    <span>&nbsp;&nbsp;
                      <a onClick={e => {
                        e.preventDefault();
                        onAddShippingRange(
                          Object.values(this.state.shipping_ranges).length ? Object.values(this.state.shipping_ranges)[Object.values(this.state.shipping_ranges).length - 1].shipping_range_id : null,
                          Object.values(this.state.shipping_ranges).length ? parseInt(Object.values(this.state.shipping_ranges)[Object.values(this.state.shipping_ranges).length - 1].min) : 0,
                        );
                      }}>+ add Range</a>
                    </span>
                  </td>
                  <td>Shipping Charge</td>
                </tr>
              </thead>
              <tbody>
                {Object.values(this.state.shipping_ranges).map((r, i) =>
                  <tr key={r.shipping_range_id}>
                    <td style={{ paddingTop: 0, fontWeight: 'bold', paddingRight: 0, fontSize: '22px' }}>
                      <a onClick={e => {
                        e.preventDefault(); onDeleteShippingRange(
                          r.shipping_range_id,
                          i - 1 >= 0 ? Object.values(this.state.shipping_ranges)[i - 1].shipping_range_id : null,
                          i - 1 >= 0 ? r.max : null
                        );
                      }}>&times;</a>
                    </td>
                    <td>
                      <span>{shop.currency.currency_symbol}&nbsp;</span>
                      <input type="number" placeholder="Min" value={r.min}
                        style={{ width: '100px', display: 'inline-block', marginRight: '10px' }}
                        onChange={e => this.handleChangeRangeInput('min', e.target.value, r.shipping_range_id)}
                        onBlur={e => onUpdateShippingRange(
                          r.shipping_range_id,
                          'min',
                          r.min,
                          i - 1 >= 0 ? Object.values(this.state.shipping_ranges)[i - 1].shipping_range_id : null,
                          i - 1 >= 0 ? r.min : null
                        )(parseInt(e.target.value))} />
                      <span>-</span>
                      {r.max ? <span> below {shop.currency.currency_symbol}</span> : null}
                      {r.max ?
                        <input type="number" placeholder="Max" value={r.max} disabled={true}
                          style={{ width: '100px', display: 'inline-block', marginLeft: '10px' }} />
                      :
                        <span style={{ marginLeft: '50px', fontSize: '23px' }}>&infin;</span>
                      }
                    </td>
                    <td>
                      <span>{shop.currency.currency_symbol}&nbsp;</span>
                      <input type="number" placeholder="Value" value={r.value}
                      onChange={e => this.handleChangeRangeInput('value', e.target.value, r.shipping_range_id)}
                      onBlur={e => onUpdateShippingRange(r.shipping_range_id, 'value', r.value)(parseInt(e.target.value))} style={{ width: '100px', display: 'inline-block' }} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  renderCheckoutLimitSection() {
    const { shop, onChangeShop, onUpdateShop } = this.props;

    return (
      <div className="row" style={{ marginTop: '2rem' }}>
        <div className="small-12 columns">
          <h5>Checkout Limit
            <span className="explanation"> Allows you to set a maximum spend amount&nbsp;&nbsp;</span>
          </h5>
          <div className="row">
            <div className="small-1 columns" style={{ width: '2%', marginTop: '10px' }}>{shop.currency.currency_symbol}&nbsp;</div>
            <LabelledInput
              className="small-11 medium-4 columns end"
              placeholder="Leave blank if not required"
              value={shop.per_checkout_limit}
              onChange={onChangeShop('per_checkout_limit', shop.per_checkout_limit)}
              onBlur={onUpdateShop('per_checkout_limit', shop.per_checkout_limit)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderCollectCustomerPOSection() {
    const { shop, onUpdateShop } = this.props;
    const collectCustomerPOOptions = [
      { key: 'NO', value: 'No' },
      { key: 'OPTIONAL', value: 'Optional' },
      { key: 'REQUIRED', value: 'Required' }
    ];
    return (
      <div className="row" style={{ marginTop: '2rem' }}>
        <div className="small-12 columns">
          <h5>Collect Customer PO</h5>
          <div className="small-12 columns" style={{ padding: 0 }}>
            <LabelledRadio
	      options={collectCustomerPOOptions}
	      value={shop.collect_customer_po}
	      onChange={onUpdateShop('collect_customer_po', shop.collect_customer_po)}
	    />
          </div>
        </div>
      </div>
    );
  }

  renderMetadataListSection() {
    const { shop, onAddMetadataList, onUpdateMetadataList, onDeleteMetadataList, onEditMetadataList } = this.props;
    const metaDataList = shop.metadata_list;
    return (
      <MetaData parent_type='SHOP' metaDataList={metaDataList} addMetaDataList={onAddMetadataList} updateMetaDataList={onUpdateMetadataList} deleteMetaDataList={onDeleteMetadataList} editMetaDataList={onEditMetadataList}/>
    );
  }

  renderDiscountSection() {
    const { discounts, onCreateDiscount, onUpdateDiscount, onChangeDiscount, onDeleteDiscount } = this.props;
    const discount_types = [
      { key: 'VALUE', value: 'Value $' },
      { key: 'PERCENT', value: 'Percent %'}
    ];

    const date = new Date();
    const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`;

    const enabled = (date_active, date_expiry) => {
      if(date_active && date_active != '0000-00-00 00:00:00' && (toTimestamp(date_active) <= toTimestamp(today))) {
        if(!date_expiry || date_expiry == '0000-00-00 00:00:00') {
          return true;
        }else if(date_expiry && date_expiry != '0000-00-00 00:00:00' && (toTimestamp(today) < toTimestamp(date_expiry))) {
          return true;
        }
      }

      return false;
    };

    const enableDiscount = (discount_id, date_active, date_expiry) => {
      onUpdateDiscount(discount_id, 'date_active', date_active)(today);
      onUpdateDiscount(discount_id, 'date_expiry', date_expiry)('0000-00-00 00:00:00');
    };

    const disableDiscount = (discount_id, date_active, date_expiry) => {
      onUpdateDiscount(discount_id, 'date_active', date_active)('0000-00-00 00:00:00');
      onUpdateDiscount(discount_id, 'date_expiry', date_expiry)('0000-00-00 00:00:00');
    };

    return (
      <Theme>
        {discounts.map(d =>
          <div key={d.discount_id} className="row" style={{ padding: '15px' }}>
            <div className="box tag-table">
              <Row>
                <Col padded xs={10}>
                  <h5><CouponIcon color={"black"} mr={5} style={{verticalAlign: 'middle'}}/> Coupon</h5>
                </Col>
                <Col padded xs={2}>
                  <a className="button small" onClick={e => {e.preventDefault(); onDeleteDiscount(d.discount_id);}}>Delete</a>
                </Col>
              </Row>
              <Row>
                <Col padded xs={4}>
                  <LabelledInput label="Code" value={d.code}
                    onChange={onChangeDiscount(d.discount_id, 'code', d.code)}
                    onBlur={onUpdateDiscount(d.discount_id, 'code', d.code)} />
                </Col>
                <Col padded xs={8} style={{ paddingTop: '40px' }}>
                  <LabeledCheckbox label={<div data-tip data-for="single_use">Single use (per email) <InfoIcon ml={5} style={{verticalAlign: 'middle'}} />
                      <ReactTooltip id="single_use">
                        <p style={{ padding: '5px 10px 5px 10px' }}>If checked, each customer can redeem this coupon during checkout only once per unique email address.</p>
                      </ReactTooltip>
                    </div>}
                    checked={d.single_use == 1}
                    onChange={e => onUpdateDiscount(d.discount_id, 'single_use', d.single_use)(d.single_use == 1 ? 0 : 1)} />
                </Col>
              </Row>
              <Row>
                <Col padded xs={6} sm={3}>
                  <LabelledSelect label="Type"
                    labelStyle={{height: "24px", marginBottom: "4px"}}
                    value={d.type}
                    options={discount_types}
                    onChange={onUpdateDiscount(d.discount_id, 'type', d.type)} />
                </Col>
                <Col padded xs={6} sm={3}>
                  <LabelledInput label="Discount" labelStyle={{height: "24px", marginBottom: "4px"}} style={{display: 'inline-block'}} value={d.value}
                    onChange={onChangeDiscount(d.discount_id, 'value', d.value)}
                    onBlur={onUpdateDiscount(d.discount_id, 'value', d.value)} />
                </Col>
                <Col padded xs={6} sm={3}>
                  <LabelledInput label="Minimum spend" value={d.prerequisite_amount}
                      info={<div style={{ display: 'inline-block', height: "24px", marginBottom: "4px"}} data-tip data-for="minimum_spend"><InfoIcon ml={5} style={{verticalAlign: 'middle'}} />
                        <ReactTooltip id="minimum_spend">
                          <p style={{ padding: '5px 10px 5px 10px' }}>
                            A subtotal the customer must have before checkout to use this coupon.
                            <br />
                            Excludes shipping cost and taxes
                          </p>
                        </ReactTooltip>
                      </div>}
                      onChange={onChangeDiscount(d.discount_id, 'prerequisite_amount', d.prerequisite_amount)}
                      onBlur={onUpdateDiscount(d.discount_id, 'prerequisite_amount', d.prerequisite_amount)} />
                </Col>
              </Row>
              <Row>
                <Col padded xs>
                  <div className="rich-text-editor">
                    <label>Description</label>
                    <textarea
                      value={d.description}
                      onChange={e => onChangeDiscount(d.discount_id, 'description', d.description)(e.target.value)}
                      onBlur={e => onUpdateDiscount(d.discount_id, 'description', d.description)(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col padded xs={6} sm={4}>
                  <LabelledInput
                    type="date"
                    label="Active Date"
                    value={d.date_active}
                    onChange={onChangeDiscount(d.discount_id, 'date_active', d.date_active)}
                    onBlur={onUpdateDiscount(d.discount_id, 'date_active', d.date_active)}
                  />
                </Col>
                <Col padded xs={6} sm={4}>
                  <LabelledInput
                    type="date"
                    label="Expiry Date"
                    value={d.date_expiry}
                    onChange={onChangeDiscount(d.discount_id, 'date_expiry', d.date_expiry)}
                    onBlur={onUpdateDiscount(d.discount_id, 'date_expiry', d.date_expiry)}
                  />
                </Col>
                <Col padded xs={6} sm={4} style={{ paddingTop: '35px' }}>
                  <Toggle>
                    <ToggleLink selected={enabled(d.date_active, d.date_expiry)} style={{ paddingTop: '0px' }}
                      onClick={e => {e.preventDefault(); enableDiscount(d.discount_id, d.date_active, d.date_expiry);}}>Active</ToggleLink>
                    <ToggleLink selected={!enabled(d.date_active, d.date_expiry)} style={{ paddingTop: '0px' }}
                      onClick={e => {e.preventDefault(); disableDiscount(d.discount_id, d.date_active, d.date_expiry);}}>Inactive</ToggleLink>
                  </Toggle>
                </Col>
              </Row>
              <Row>
                <Col padded xs>
                  <label htmlFor="domains_allowed" style={{marginBottom: "4px"}}>Valid email domains
                    <div style={{ display: 'inline-block'}} data-tip data-for="domains_allowed">
                      <InfoIcon ml={5} style={{verticalAlign: 'middle'}}/>
                      <ReactTooltip id="domains_allowed">
                        <p style={{ padding: '5px 10px 5px 10px' }}>
                          Checkout can be restricted to specific email domains when added to the list below.
                          <br />
                          If it is empty, customer can checkout with any email address.
                          <br />
                          The format of the domain should be a naked domain eg. commonsku.com
                        </p>
                      </ReactTooltip>
                    </div>
                  </label>
                  <TagsInput
                    value={d.domains_allowed}
                    onChange={onUpdateDiscount(d.discount_id, 'domains_allowed', d.domains_allowed)}
                    inputProps={{ placeholder: 'Add a domain' }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        )}
        {discounts.length < 10 ?
          <div className="row" style={{ padding: '15px' }} onClick={onCreateDiscount}>
            <div className="box" style={{ textAlign: 'center' }}>
              <Link style={{ fontStyle: 'bold', verticalAlign: 'middle' }}><CouponIcon color={"#02c0da"} mr={5} style={{verticalAlign: 'middle'}} /> New Coupon</Link>
            </div>
          </div>
        : null}
      </Theme>
    );
  }

  render() {
    const { shop, onChangeShop, onUpdateShop } = this.props;

    return (
      <div className="main-content shop-config-container">
        {this.renderGeneralSection()}
        {shop.shop_type === 'popup' ? this.renderFreeProductOption() : null}
        {shop.client_id && shop.is_shop_free != 1? this.renderShippingChargeSection() : null}
        <div className="row section-checkout" style={{ padding: '15px' }}>
          <div className="box">
            <h4>Checkout
              {shop.client_id && shop.aggregate == 0 && <span className="explanation">The customer will need to type their name and address, or select them from a pre-defined list.&nbsp;&nbsp;</span>}
              {!shop.client_id && <span className="explanation">The customer will need to type their name and address.&nbsp;&nbsp;</span>}
            </h4>
            <ShopCheckoutConfig shop_id={shop.shop_id} />
            {shop.client_id && shop.aggregate == 0 ? this.renderContactSection() : null}
            {shop.client_id && shop.aggregate == 0 ? this.renderAddressSection() : null}
            {shop.client_id && shop.aggregate == 1 && shop.is_shop_free != 1 ? this.renderCreditCardSection() : null}
            {shop.client_id && shop.aggregate == 1 && shop.is_shop_free != 1 && shop.is_shop_free !=1 ? this.renderCheckoutLimitSection() : null}
            {this.renderMetadataListSection()}
            {shop.client_id && shop.aggregate == 0 ? this.renderCollectCustomerPOSection() : null}
            <div className="row" style={{ marginTop: '2rem' }}>
              <div className="small-12 columns">
                <h5>Message</h5>
                <RichTextEditor
                  value={shop.checkout_message ? shop.checkout_message : 'Thanks for your order.'}
                  onChange={onChangeShop('checkout_message')}
                  onBlur={onUpdateShop('checkout_message')}
                />
              </div>
            </div>
          </div>
        </div>
        {'company' === shop.shop_type ? this.renderInventorySection() : null}
        {shop.client_id && shop.aggregate == 1 && shop.is_shop_free != 1 ? this.renderDiscountSection() : null}
        <br /><br />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity:state.identity,
  order: getOrderByNumber(state, { form_number: ownProps.shop.shop_number, order_type: 'SHOP' }),
  inventory: getInventoryByShopId(state, ownProps.shop),
  company_data: getCompanyData(state),
  event_types: getEventTypeOptions(state),
  project_templates: getJobTemplateOptions(state),
  chat_bots: getChatBotOptions(state),
  statuses: getStatusOptions(state, { order_type: 'SHOP' }),
  tags: getTags(state),
  users: getUserOptions(state),
  shop_addresses: getAddressDropdown(state, { parent_id: ownProps.shop.shop_id }).filter(a => a.parent_type === 'SHOP'),
  shop_billing_contacts: getContactDropdown(state, { parent_id: ownProps.shop.shop_id }).filter(c => ['SHOP', 'SHOP-BILLING'].indexOf(c.company_type) !== -1),
  shop_shipping_contacts: getContactDropdown(state, { parent_id: ownProps.shop.shop_id }).filter(c => ['SHOP', 'SHOP-SHIPPING'].indexOf(c.company_type) !== -1),
  shop_contacts: getContactDropdown(state, { parent_id: ownProps.shop.shop_id }).filter(c => c.company_type === 'SHOP'),
  client_addresses: ownProps.shop.client_id ? getAddressDropdown(state, { parent_id: ownProps.shop.client_id }) : null,
  clientAddressOptions: ownProps.shop.client_id ? getAddressOptions(state, { parent_id: ownProps.shop.client_id }) : null,
  clientContactOptions: ownProps.shop.client_id ? getContactOptions(state, { parent_id: ownProps.shop.client_id }) : null,
  currencies: getCurrencyOptions(state),
  billing_address: getAddress(state, { address_id: ownProps.shop.billing_address_id }),
  billing_contact: getContact(state, { contact_id: ownProps.shop.billing_contact_id }),
  shipping_address: getAddress(state, { address_id: ownProps.shop.shipping_address_id }),
  shipping_contact: getContact(state, { contact_id: ownProps.shop.shipping_contact_id }),
  discounts: Object.values(state.entities.discounts),
  valid_tags:Object.values(state.entities.shops[ownProps.shop.shop_id].free_shop_data).map(f => f.domain),
  hasCapabilities: getIdentityUtils(state.identity).hasCapabilities
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadOrder: order_id => dispatch(createLoadOrder(order_id)),
  loadInventory: () => dispatch(loadInventory(ownProps.shop.inventory_id)),
  enableInventory: () => dispatch(enableInventory(ownProps.shop.shop_id)),
  onChangeShop: (field, previous_value) => value => dispatch(finalizeUpdateShop(ownProps.shop.shop_id, field, previous_value, value, ownProps)),
  onUpdateShop: (field, previous_value) => value => dispatch(updateShop(ownProps.shop.shop_id, field, previous_value, value)),
  onUpdateOrder: (order_id, field, previous_value) => value => dispatch(updateOrder(order_id, field, previous_value, value)),
  onUploadFile: (file) => dispatch(createUploadFile('SHOP', 'PORTAL', file)),
  onAddContact: (type) => dispatch(createContactListPopup(ownProps.shop.shop_id, ownProps.shop.client_id, type)),
  onAddShippingAddress: (allow_credit_card_checkout) => {
    return dispatch(createAddressListPopup(
      ownProps.shop.shop_id, ownProps.shop.client_id, 'SHIPPING',
      allow_credit_card_checkout, ownProps.shop.zip2tax
    ));
  },
  onAddBillingAddress: () => dispatch(createAddressListPopup(ownProps.shop.shop_id, ownProps.shop.client_id, 'BILLING')),
  onAddShippingRange: (last_shipping_range_id, last_shipping_range_min) => {
    dispatch(createShippingRange(ownProps.shop.shop_id));
    if(last_shipping_range_id) {
      dispatch(updateShippingRange(ownProps.shop.shop_id, last_shipping_range_id, 'max', null, last_shipping_range_min + 10));
    }
  },
  onUpdateShippingRange: (shipping_range_id, field, previous_value, previous_shipping_range_id, updated_shipping_range_max) => value => {
    dispatch(updateShippingRange(ownProps.shop.shop_id, shipping_range_id, field, previous_value, value));
    if(previous_shipping_range_id) {
      dispatch(updateShippingRange(ownProps.shop.shop_id, previous_shipping_range_id, 'max', null, updated_shipping_range_max));
    }
  },
  onDeleteShippingRange: (shipping_range_id, previous_shipping_range_id, updated_shipping_range_max) => {
    dispatch(deleteShippingRange(shipping_range_id, ownProps.shop.shop_id));
    if(previous_shipping_range_id) {
      dispatch(updateShippingRange(ownProps.shop.shop_id, previous_shipping_range_id, 'max', null, updated_shipping_range_max));
    }
  },
  onEditMetadataList: (metadata_id) => dispatch(createMetadataListPopup(ownProps.shop.shop_id, metadata_id, 'SHOP')),
  onAddMetadataList: (label) => {
    dispatch(createMetadata(label, null, ownProps.shop.shop_id, 'SHOP'));
  },
  onUpdateMetadataList: (metadata_id, field, previous_value) => value => {
    dispatch(updateMetadata(ownProps.shop.shop_id, metadata_id, field, previous_value, value, 'SHOP'));
  },
  onDeleteMetadataList: (metadata_id) => {
    dispatch(deleteMetadata(metadata_id, ownProps.shop.shop_id, 'SHOP'));
  },
  onCreateUpdateAddress: (address_id, field, previous_value) => value => dispatch(createUpdateAddress(address_id, field, previous_value, value)),
  onCreateDiscount: () => dispatch(createDiscount(ownProps.shop.shop_id, 'SHOP')),
  onChangeDiscount: (discount_id, field, previous_value) => value => dispatch(finalizeUpdateDiscount(discount_id, field, previous_value, value)),
  onUpdateDiscount: (discount_id, field, previous_value) => value => dispatch(updateDiscount(discount_id, field, previous_value, value)),
  onDeleteDiscount: discount_id => dispatch(deleteDiscount(discount_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
